<template>
  <CoachListView v-model="_searchCoachName" :champList="champList" :coachList="coachList" :getFilteredCoachList="_getFilteredCoachList" :positionFilterList="positionFilterList" :selectedChampFilters="selectedChampFilters" :selectedPositionFilters="selectedPositionFilters" :selectedTierFilters="selectedTierFilters" :tierFilterList="tierFilterList" @setChampFilter="setChampFilter" @setPositionFilter="setPositionFilter" @setTierFilter="setTierFilter"/>
</template>

<script>
import _debounce from 'lodash/debounce';
import cursorFetcher from '@shared/mixins/cursorFetcher';
import CoachListView from '@/views/components/coaching/coachList/CoachListView.vue';
import { removeEventHandler, setEventHandler } from '@/utils/eventBusUtils';

export default {
  name: 'CoachList',
  components: {
    CoachListView,
  },
  mixins: [cursorFetcher({
    coachList: ({ cursor, services }) => services.coaching.getCoachList({ size: 100, cursor, order: 'isOnline DESC' }),
  })],
  data: () => ({
    coachList: /** @type {CoachList} */ [],
    searchCoachName: '',
    selectedTierFilters: [],
    selectedChampFilters: [],
    selectedPositionFilters: [],
    champList: [],
    eventBus: null,
  }),
  computed: {
    _searchCoachName: {
      get() {
        return this.searchCoachName;
      },
      set(v) {
        this.searchCoachName = v;
        this._getFilteredCoachList();
      },
    },
    tierFilterList() {
      const list = ['CHALLENGER', 'GRANDMASTER', 'MASTER', 'DIAMOND_1', 'DIAMOND_2', 'DIAMOND_3', 'DIAMOND_4', 'PLATINUM_0', 'GOLD_0'];
      return list;
    },
    positionFilterList() {
      const list = ['all', 'top', 'jungle', 'middle', 'bottom', 'supporter'];
      return list;
    },
  },
  methods: {
    setTierFilter(v) {
      this.selectedTierFilters = v;
      this._getFilteredCoachList();
    },
    setChampFilter(v) {
      this.selectedChampFilters = v;
      this._getFilteredCoachList();
    },
    setPositionFilter(v) {
      this.selectedPositionFilters = v;
      this._getFilteredCoachList();
    },
    makeFilterQuery() {
      let rankQuery = '';
      let positionQuery = '';
      let champQuery = '';

      if (this.selectedTierFilters.length) {
        this.selectedTierFilters.forEach(t => {
          switch (t) {
            case 'CHALLENGER': {
              rankQuery += `challenger,`;
              break;
            }
            case 'GRANDMASTER': {
              rankQuery += `grandmaster,`;
              break;
            }
            case 'MASTER': {
              rankQuery += 'master,';
              break;
            }
            case 'DIAMOND_1':
            case 'DIAMOND_2':
            case 'DIAMOND_3':
            case 'DIAMOND_4': {
              rankQuery += `${String(t).toLowerCase()},`;
              break;
            }
            case 'PLATINUM_0': {
              rankQuery += `platinum_1,platinum_2,platinum_3,platinum_4,`;
              break;
            }
            case 'GOLD_0': {
              rankQuery += `gold_1,gold_2,gold_3,gold_4,`;
              break;
            }
            default:
          }
        });
      }

      if (rankQuery) rankQuery = `rank in ${rankQuery.slice(0, -1)}`;

      if (this.selectedPositionFilters.length) {
        this.selectedPositionFilters.forEach(p => {
          switch (p) {
            case 'all':
              positionQuery += 'all,';
              break;
            case 'top':
              positionQuery += 'top,';
              break;
            case 'jungle':
              positionQuery += 'jungle,';
              break;
            case 'middle':
              positionQuery += 'mid,';
              break;
            case 'bottom':
              positionQuery += 'ad_carry,';
              break;
            case 'supporter':
              positionQuery += 'support,';
              break;
            default:
          }
        });
      }

      if (positionQuery) positionQuery = `lolPosition in ${positionQuery.slice(0, -1)}`;

      if (this.selectedChampFilters.length) {
        this.selectedChampFilters.forEach(c => {
          const champCode = c?.code;
          champQuery += `${champCode},`;
        });
      }

      if (champQuery) champQuery = `lolChampion in ${champQuery.slice(0, -1)}`;

      return { rankQuery, lolPositionQuery: positionQuery, champQuery };
    },
    _getFilteredCoachList() {
      const order = 'isOnline DESC';
      const args = { name: this.searchCoachName, ...this.makeFilterQuery(), order };
      this.getFilteredCoachList(args);
    },
    getFilteredCoachList: _debounce(async function (args) {
      this.coachList = await this.$services.coaching.getCoachList(args);
    }, 400, { trailing: true }),
    coachStatusChangeHandler(e) {
      const { coachId, status } = e;
      const coachItems = this.coachList?.items;
      for (let i = 0; i < coachItems.length; i++) {
        if (coachItems[i]?.coachId === coachId) {
          coachItems[i].status = status;
          break;
        }
      }
    },
    async getCoachList() {
      this.coachList = await this.$services.coaching.getCoachList();
    },
    async resetData() {
      this.champList = await this.$services.coaching.getLolChampsAll();
      await this.fetchInitAll();
    },
  },
  mounted() {
    setEventHandler('coach-list-get-list', this.getCoachList.bind(this));
    this.resetData();
    this.$bindPushEvent('public-coach', 'coach_status_changed', this.coachStatusChangeHandler);
  },
  beforeDestroy() {
    removeEventHandler('coach-list-get-list');
    this.$unbindPushEvent('public-coach', 'coach_status_changed', this.coachStatusChangeHandler);
  },
};
</script>
