<template>
  <router-link coach-list-item :to="coachPageRoute">
    <div class="coach-avatar-holder">
      <Avatar :info="user" :defaultImgType="'fruit'" class="coach-avatar" dimension="104x104" userProfile />
      <div :class="{'online': status === 'ONLINE', 'coaching': status === 'COACHING' }" class="coach-conn-status"></div>
    </div>
    <div class="info-left-holder">
      <div class="coach-name-wrap">
        <div class="coach-name">{{ name }}</div>
      </div>
      <div class="tags">
        <FilledBadge v-if="tags.length > 0" :color="tagColor(tags[0].priority)">
          <span>{{ tags[0].name }}</span>
        </FilledBadge>
      </div>
    </div>
    <div class="info-right-holder">
      <div class="rating-review-holder">
        <PointIcon :showCurrency="false" :value="recommendLessonPrice"></PointIcon>
        <div class="rating-review">
          <FilledBadge v-if="!reviewCount" color="dodger-blue">
            <span>NEW</span>
          </FilledBadge>
          <RatingStarPoint v-else :value="reviewRating" />
          <span v-if="reviewCount" class="review-count">({{ reviewCount }})</span>
        </div>
      </div>
      <div class="tier-holder">
        <CdnImg v-if="showTierImg" :src="tierImg" class="tier-img">
          <template #skeleton>
            <div class="skeleton"></div>
          </template>
        </CdnImg>
        <img v-else alt="official-emblem" class="official-emblem" src="/img/icon/icon-emblem-official.svg" />
      </div>
    </div>
  </router-link>
</template>

<script>
import Avatar from '@shared/components/common/Avatar.vue';
import CdnImg from '@shared/components/common/CdnImg.vue';
import { TAG_COLOR } from '@/constants/coach';
import FilledBadge from '@/views/components/coaching/FilledBadge.vue';
import PointIcon from '@/views/components/coaching/PointIcon.vue';
import RatingStarPoint from '@/views/components/coaching/RatingStarPoint.vue';
import coach from '@/mixins/coach';

export default {
  name: 'CoachListItem',
  components: { RatingStarPoint, Avatar, FilledBadge, PointIcon, CdnImg },
  mixins: [coach],
  props: {
    coach: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({}),
  computed: {
    _coach() {
      return /** @type {Coach} */ this?.coach;
    },
    coachId() {
      return this?._coach?.coachId;
    },
    user() {
      return this?._coach?.user;
    },
    name() {
      return this?._coach?.user?.nickname;
    },
    tags() {
      return this?._coach?.tags || [];
    },
    recommendLessonPrice() {
      return this?._coach?.recommendLessonPrice;
    },
    reviewCount() {
      return this?._coach?.reviewCount;
    },
    reviewRating() {
      return this?._coach?.reviewRating;
    },
    tierImg() {
      return this?._coach?.rank?.logoUrl;
    },
    isOfficial() {
      return this?._coach?.tags && this?._coach?.tags?.includes('OFFICIAL');
    },
    status() {
      return this?._coach?.status;
    },
    showTierImg() {
      return !this.isOfficial && this.tierImg;
    },
    coachPageRoute() {
      return this.getCoachPageRoute(this.coachId) ?? '#';
    },
  },
  methods: {
    tagColor(tag) {
      return TAG_COLOR[tag] || TAG_COLOR.default;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-list-item] { .flex; .h(68); .p(0, 12); .pointer; .br(4); border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  &:hover {.bgc(rgba(255, 255, 255, 0.1));}
  .coach-avatar-holder { .flex; .items-center; .rel;
    .coach-avatar {.w(52); .h(52); .br(50%); }
    .coach-conn-status {.abs; z-index: 1; .b(8); .l(0); .w(14); .h(14); .bgc(#5f5f5f); border: 2px solid #1f2226; .br(50%);
      &.online { .bgc(#0ce757); }
      &.coaching { .bgc(#6aa6ff); }
    }
  }
  .info-left-holder { .flex; flex-direction: column; .ml(8); .w(100%); flex-grow: 1; justify-content: center;
    .coach-name-wrap { display: grid;
      .coach-name { .fs(16); .c(#fff); .bold; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
    }
    .tags {
      [filled-badge] { .mr(4); .fs(11);}
    }
  }
  .info-right-holder { .flex; .items-center;
    .rating-review-holder { .flex; .mr(8); flex-direction: column; align-items: flex-end;
      [point-icon] {
        .amount {.c(#fff);}
      }
      .rating-review { .flex;
        [filled-badge] { .fs(11);}
        .review-count { .ml(4); .fs(14); .c(#787781);}
      }
    }
    .tier-holder { .flex; .w(40); .h(40); .items-center; box-sizing: border-box; .bgc(#293039); .br(4);
      .tier-img { .w(40); .h(40); .br(4);
        .skeleton { .rel; overflow: hidden;
          &:after { .shine-anim; }
        }
      }
      .official-emblem {.w(40); .h(40); .br(4);}
    }
  }
}

</style>
