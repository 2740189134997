<template>
  <div rating-star-point>
    <img :src="_color">
    <span v-if="value > 0" class="rating">{{ _value }}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'RatingStarPoint',
  props: {
    color: { type: String, default: 'blue' },
    value: { type: Number, default: 0 },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    _color() {
      switch (this.color) {
        case 'yellow':
          return '/img/icon/icon-yellow-star.svg';
        case 'blue':
        default:
          return '/img/icon/icon-blue-star.svg';
      }
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[rating-star-point] { .flex; .items-center;
  .rating {.ml(4); .c(#2c81ff); .fs(14);}
}
</style>
