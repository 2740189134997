<template>
  <div coach-list-view>
    <div class="top-holder">
      <div class="search-input-holder">
        <TextInput v-model="searchCoachName" :placeholder="$t('coaching.IG_search_coach')" class="search-input"
          theme="black" />
        <img alt="icon-search" class="search-icon" src="/img/icon/icon-search-gray.svg" />
      </div>
      <div class="filter-btn-holder">
        <button class="filter-btn" @click="filterBtnHandler">
          <img alt="icon-filter" class="filter-btn-img" src="/img/icon/ico_filter.svg">
          <span>{{ $t('coaching.PT_FILTER') }}</span>
        </button>
      </div>
      <div v-show="showFilterLayer" class="filter-layer">
        <div class="filter-layer-top">
          <h2 class="filter-layer-title">{{ $t('coaching.MS_search_coachfillter') }}</h2>
          <filledBadge class="reset-btn" color="white" @click.native="filterResetBtnHandler">
            <span>{{ $t('coaching.BA_reset') }}</span>
          </filledBadge>
        </div>
        <div class="filter-layer-content">
          <div class="filter-tab">
            <div :class="{active: filterMode === 'tier'}" class="filter-tab-item tier" @click="filterMode = 'tier'">
              <span>{{ $t('coaching.BT_lol_grade') }}</span>
            </div>
            <div :class="{active: filterMode === 'champ'}" class="filter-tab-item champ" @click="filterMode = 'champ'">
              <span>{{ $t('coaching.BA_coachcard_main_cham') }}</span>
            </div>
            <div :class="{active: filterMode === 'position'}" class="filter-tab-item position" @click="filterMode = 'position'">
              <span>{{ $t('coaching.BT_lol_position') }}</span>
            </div>
          </div>
          <div class="filter-list-wrap">
            <ul v-if="filterMode === 'tier'" class="filter-list tier">
              <li v-for="tierItem in tierFilterList" :key="tierItem" :class="{'selected': _selectedTierFilters.includes(tierItem)}" class="filter-list-item" @click="tierItemHandler(tierItem)">
                <span class="filter-text">{{ $t(`coaching.LOL_TIER_${tierItem}`) }}</span>
                <img alt="icon-check" class="check-icon" src="/img/icon/icon-check-dark.svg">
              </li>
            </ul>
            <ul v-if="filterMode === 'champ'" class="filter-list champ">
              <li v-for="champ in champList" :key="champ.championId" :class="{'selected': _selectedChampFilters.includes(champ)}" class="filter-list-item" @click="champHandler(champ)">
                <span class="filter-text">{{ champ.koName }}</span>
                <img alt="icon-check" class="check-icon" src="/img/icon/icon-check-dark.svg">
              </li>
            </ul>
            <ul v-if="filterMode === 'position'" class="filter-list position">
              <li v-for="positionItem in positionFilterList" :key="positionItem" :class="{'selected': _selectedPositionFilters.includes(positionItem)}" class="filter-list-item" @click="positionItemHandler(positionItem)">
                <div class="filter-item-left">
                  <component :is="lolPositionComp[positionItem]" alt="icon-lol-position" class="icon-lol-position" />
                  <span class="filter-text">{{ $t(`coaching.MC_lol_position_${positionItem}`) }}</span>
                </div>
                <img alt="icon-check" class="check-icon" src="/img/icon/icon-check-dark.svg">
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="hasFilter" class="filter-status">
      <div v-show="_selectedTierFilters.length" class="filter-status-item tier" @click="filterUnset('tier')">
        <span>{{
            $t('coaching.BT_lol_grade')
          }}
        </span>
        <img alt="icon-close" class="close-btn" src="/img/icon/close.svg">
      </div>
      <div v-show="_selectedChampFilters.length" class="filter-status-item champ" @click="filterUnset('champ')">
        <span>{{
            $t('coaching.BT_lol_cham')
          }}
        </span>
        <img alt="icon-close" class="close-btn" src="/img/icon/close.svg">
      </div>
      <div v-show="_selectedPositionFilters.length" class="filter-status-item position" @click="filterUnset('position')">
        <span>{{
            $t('coaching.BT_lol_position')
          }}
        </span>
        <img alt="icon-close" class="close-btn" src="/img/icon/close.svg">
      </div>
    </div>
    <div class="coach-list-wrap-out">
      <div class="coach-list-wrap">
        <div v-if="favoriteCoachList.length" class="coach-list favorite">
          <h2 class="subject-text">{{ '즐겨찾기' + `(${favoriteCoachList.length})` }}</h2>
          <div class="coach-item-holder">
            <CoachListItem v-for="(coach, i) in favoriteCoachList" :key="`online-${i}`" :coach="coach" @click.native="coachItemHandler(coach)" />
          </div>
        </div>
        <div v-if="onlineCoachList.length" class="coach-list online">
          <h2 class="subject-text">{{ $t('coaching.MC_coachlist_waiting') + `(${onlineCoachList.length})` }}</h2>
          <div class="coach-item-holder">
            <CoachListItem v-for="(coach, i) in onlineCoachList" :key="`online-${i}`" :coach="coach" @click.native="coachItemHandler(coach)" />
          </div>
        </div>
        <div v-if="coachingCoachList.length" class="coach-list coaching">
          <h2 class="subject-text">{{ $t('coaching.MC_coachlist_lesson') + `(${coachingCoachList.length})` }}</h2>
          <div class="coach-item-holder">
            <CoachListItem v-for="(coach, i) in coachingCoachList" :key="`coaching-${i}`" :coach="coach" @click.native="coachItemHandler(coach)" />
          </div>
        </div>
        <div v-if="offlineCoachList.length" class="coach-list offline">
          <h2 class="subject-text">{{ $t('coaching.MC_coachlist_offline') + `(${offlineCoachList.length})` }}</h2>
          <div class="coach-item-holder">
            <CoachListItem v-for="(coach, i) in offlineCoachList" :key="`offline-${i}`" :coach="coach" @click.native="coachItemHandler(coach)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '@shared/components/common/input/TextInput.vue';
import { COACH_STATUS_ONLINE, COACH_STATUS_COACHING, COACH_STATUS_OFFLINE } from '@/constants/coach';
import CoachListItem from '@/views/components/coaching/coachList/CoachListItem.vue';
import FilledBadge from '@/views/components/coaching/FilledBadge.vue';
import {
  SvgLaneAll,
  SvgLaneJungle,
  SvgLaneBottom,
  SvgLaneMid,
  SvgLaneTop,
  SvgLaneSupport,
} from '@/views/graphics/lol-lane/LazyLoadings';
import coach from '@/mixins/coach';

export default {
  name: 'CoachListView',
  mixins: [coach],
  components: {
    FilledBadge, CoachListItem, TextInput, SvgLaneAll, SvgLaneJungle, SvgLaneBottom, SvgLaneMid, SvgLaneTop, SvgLaneSupport,
  },
  props: {
    coachList: {
      default: {},
    },
    tierFilterList: {
      type: Array,
      default: () => ([]),
    },
    positionFilterList: {
      type: Array,
      default: () => ([]),
    },
    champFilterList: {
      type: Array,
      default: () => ([]),
    },
    champList: {
      type: Array,
      default: () => ([]),
    },
    value: {
      /** coach name searching text */
      type: String,
      default: '',
    },
    selectedTierFilters: {
      type: Array,
      default: () => ([]),
    },
    selectedChampFilters: {
      type: Array,
      default: () => ([]),
    },
    selectedPositionFilters: {
      type: Array,
      default: () => ([]),
    },
    getFilteredCoachList: {
      type: Function,
      default: () => ([]),
    },
  },
  data: () => ({
    showFilterLayer: false,
    filterMode: /** @type { 'tier' | 'champ' | 'position' } */ 'champ',
  }),
  computed: {
    searchCoachName: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    _selectedTierFilters: {
      get() {
        return this.selectedTierFilters;
      },
      set(v) {
        this.$emit('setTierFilter', v);
      },
    },
    _selectedChampFilters: {
      get() {
        return this.selectedChampFilters;
      },
      set(v) {
        this.$emit('setChampFilter', v);
      },
    },
    _selectedPositionFilters: {
      get() {
        return this.selectedPositionFilters;
      },
      set(v) {
        this.$emit('setPositionFilter', v);
      },
    },
    _coachList() {
      return /** @type {CoachList} */ this.coachList || [];
    },
    coaches() {
      return this?._coachList?.items;
    },
    favoriteCoachList() {
      return (this?.coaches && this?.coaches?.filter(c => c.isLiked === true)) || [];
    },
    onlineCoachList() {
      return (this?.coaches && this?.coaches?.filter(c => c.status === COACH_STATUS_ONLINE)) || [];
    },
    coachingCoachList() {
      return (this?.coaches && this?.coaches?.filter(c => c.status === COACH_STATUS_COACHING)) || [];
    },
    offlineCoachList() {
      return (this?.coaches && this?.coaches?.filter(c => c.status === COACH_STATUS_OFFLINE)) || [];
    },
    hasFilter() {
      return this._selectedTierFilters.length || this._selectedChampFilters.length || this._selectedPositionFilters.length;
    },
    lolPositionComp() {
      return {
        all: 'svg-lane-all',
        top: 'svg-lane-top',
        middle: 'svg-lane-mid',
        jungle: 'svg-lane-jungle',
        bottom: 'svg-lane-bottom',
        supporter: 'svg-lane-support',
      };
    },
  },
  methods: {
    filterBtnHandler() {
      this.filterMode = 'champ';
      this.showFilterLayer = !this.showFilterLayer;
    },
    selectFilterItem(filters, item) {
      const tempFilters = filters.slice();
      const idx = tempFilters.indexOf(item);
      if (idx > -1) tempFilters.splice(idx, 1);
      else tempFilters.push(item);
      return tempFilters;
    },
    tierItemHandler(item) {
      this._selectedTierFilters = this.selectFilterItem(this._selectedTierFilters, item);
    },
    positionItemHandler(item) {
      this._selectedPositionFilters = this.selectFilterItem(this._selectedPositionFilters, item);
    },
    champHandler(champ) {
      this._selectedChampFilters = this.selectFilterItem(this._selectedChampFilters, champ);
    },
    filterUnset(filter) {
      switch (filter) {
        case 'tier':
          this._selectedTierFilters = [];
          break;
        case 'champ':
          this._selectedChampFilters = [];
          break;
        case 'position':
          this._selectedPositionFilters = [];
          break;
        default:
          this._selectedTierFilters = [];
          this._selectedChampFilters = [];
          this._selectedPositionFilters = [];
      }
    },
    filterResetBtnHandler() {
      this.showFilterLayer = false;
      this.filterMode = 'champ';
      this.filterUnset();
    },
    /** @param {CoachDetail} coach */
    coachItemHandler(coach) {
      if (!coach?.coachId) return;
      this.$router.push(this.getCoachPageRoute(coach?.coachId));
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-list-view] { .w(375); .h(940); .mt(22); .bgc(#1f2226); .p(32, 10); .br(12);.flex;.flex-column; .items-center;;
  .top-holder { .rel; .flex; .mb(32);.w(100%);
    .search-input-holder {.rel; .mr(18); .w(100%);
      .search-input {.h(100%);
        input {.h(48); }
      }
      .search-icon { .abs; .t(6); .r(8); pointer-events: none }
    }
    .filter-layer { .abs; .t(80); .l(-10); .w(375); .h(500); z-index: 10; .bgc(#ebebf0); .p(20); border: 1px solid rgba(0, 0, 0, 0.1); border-top: 0; border-bottom-right-radius: 20px; border-bottom-left-radius: 20px; overflow: auto;
      .filter-layer-top { .flex; .items-center; .space-between; .h(50); .mb(8);
        .filter-layer-title {.fs(16); }
        [filled-badge] { .h(28); .bgc(#fff); .c(#787781); cursor: pointer;}
      }
      .filter-layer-content {
        .filter-tab { .flex; .bgc(#fff); .br(12);
          .filter-tab-item { .flex; .items-center; .justify-center; .h(40); border: none; flex-grow: 1; .fs(14); .bgc(#fff); .c(#a4a3ae); .br(12); cursor: pointer;
            &.active { .bgc(#000); .c(#fff); }
          }
        }
        .filter-list-wrap {
          .filter-list {
            .filter-list-item { .flex; .space-between; .fs(14); .c(#787781); .p(10, 12, 10, 20); cursor: pointer;
              &:hover {.bgc(rgba(0, 0, 0, 0.1);)}
              .icon-lol-position { .w(24); .mr(6); .o(0.4);}
              .filter-item-left {.flex; .items-center;}
              .filter-text {}
              .check-icon {.w(20); object-fit: contain; .hide;}
              &.selected {
                .check-icon {display: unset;}
              }
            }
          }
        }
      }
    }

    .filter-btn-holder {
      .filter-btn {.flex; .w(70); .h(48); .items-center; .bgc(#787781); .c(#fff); .br(12); .p(8); border: none}
    }
  }
  .filter-status { .flex; .h(20); .mb(28);.w(100%);
    .filter-status-item { .flex; .items-center; .c(#787781); .br(12); .bgc(#fff); .fs(11); .mr(8); .p(0, 6); cursor: pointer;
      .close-btn { .w(16); .h(16); .o(0.4); .ml(4); }
    }
  }
  .coach-list-wrap-out {.w(100%); .scroll;flex: 1;}
  .coach-list-wrap {
    .coach-list {
      &.online {.mb(28);}
      &.favorite {.mb(28);}
      &.coaching {.mb(28);}
      .subject-text {.fs(16); .c(#fff); .mb(16);}
      .coach-item-holder {}
    }
  }
}
</style>
